import { defineStore } from "pinia";
import axios from "axios"
import { useCartStore } from "@/views/pos/stores/cart";
import { usePOSStore } from "@/views/pos/stores/pos"
import { nextTick } from "vue";
import { useAppStore } from "@/stores/app";
import JwtService from "@/core/services/JwtService";
import { useAppComponentsStore } from "./app/components/components";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useContentStore } from "./content";
import ApiService from "@/core/services/apiServicev3";
import { usePurchaseStore } from "./purchases/purchases";
import removeSpaces from "@/composables/v3/components/helpers/useRemoveSpaces";

let searchTimer;

interface RootState {
  search: any
  isLoading: boolean
}

type Payload = {
  q?: string | number;
  sort: string[];
  matchingStrategy: string;
  facets: string[];
  filter: string[][];
  offset: number;
  limit: number;
};

export const useProductStore = defineStore({
  id: "productStore",
  state: () =>
  ({
    search: { results: null, scanner: false, value: null, options: [], type: null, renderAmount: 0 },
    isLoading: false,
  } as RootState),
  getters: {
    searchQuery(state) {
      return state.search.value;
    },
    renderAmount(state) {
      return state.search.renderAmount;
    }
  },
  actions: {
    async searchProduct(query: string, component, scannerState: boolean, searchType: string) {
      const contentStore = useContentStore();
      this.search.scanner = scannerState;
      this.search.type = searchType;
      this.search.value = query;

      // Clear the timer if the user types within 1 second
      clearTimeout(searchTimer);

      // Set a new timer
      searchTimer = setTimeout(async () => {
        if (this.searchQuery != '') {

          try {

            const payload: Payload = {
              q: this.searchQuery,
              sort: ['id:desc'],
              matchingStrategy: 'all',
              facets: ['*'],
              filter: [],
              offset: 0,
              limit: 20
            }

            if (searchType === 'purchase') {
              const routeData = contentStore.routeData;
              const supplierId = routeData.supplier_id;

              payload.filter.push([`suppliers.id=${supplierId}`]);
              const results = await this.getProducts(payload);

              this.search.results = results;
            } else {
              const { data: searchResults } = await ApiService.post('apiUrlSearch', `/indexes/products/search`, payload);

              this.search.results = searchResults.hits;
            }

            await this.searchList();

            if (this.search.results.length === 1 && this.search.scanner) {
              const item = this.search.results[0];
              await this.addItem(item, component);
              this.search.value = null;
              this.search.scanner = false;
              this.search.renderAmount += 1
              nextTick(() => {
                const searchBox = document.getElementById('searchProduct') as HTMLInputElement | null;
                searchBox!.value = '';
                searchBox!.focus()
              })
            }
          }
          finally {
            //
          }
        }
      }, 300)
    },
    async getProducts(payload) {
      const { data: searchResults } = await ApiService.post('apiUrlSearch', `/indexes/products/search`, payload);

      return searchResults.hits;
    },
    async searchList() {
      const list = [] as any;
      const promises = Promise.all(
        await this.search.results.map(async (item) => {
          list.push({ value: item.id, label: item.title })
        })
      );
      await promises;
      this.search.options = list;
    },
    async addItem(productId: number, component?) {

      if (productId) {
        const { id, title, vat_percentage } = this.search.results.find(product => product.id === productId);
        const appComponentStore = useAppComponentsStore();
        const productStore = useProductStore();
        const contentStore = useContentStore();
        const appStore = useAppStore();
        const purchaseStore = usePurchaseStore();

        if (id != '' && typeof id != 'undefined') {
          this.isLoading = true;
          component.isLoading = true;
          const exists = false;

          if (!exists) {
            const getter = contentStore.routeData

            const typeMappings = {
              'quotation': {
                apiUrl: '/sales/quotations/line',
                documentValidateIndexName: 'sales_quotations_lines',
                idKey: 'quotation_id',
                isDefault: false
              },
              'purchase': {
                apiUrl: '/products/suppliers/purchases/line',
                documentValidateIndexName: 'products_suppliers_purchases_lines',
                idKey: 'purchase_id',
                isDefault: false
              },
              'sale': {
                apiUrl: '/sales/line',
                documentValidateIndexName: 'sales_lines',
                idKey: 'sale_id',
                isDefault: true
              }
            };

            const type = this.search.type || Object.keys(typeMappings).find(key => typeMappings[key].isDefault);
            const { apiUrl, idKey, documentValidateIndexName } = typeMappings[type];

            const payload = {
              product_id: id,
              name: title,
              quantity: 1,
            }

            payload[idKey] = getter.id;

            if (type === 'sale' || type === 'quotation') {
              const { status, data: product } = await productStore.getProductPrice(id, getter.customer_id);

              if (status === 'success') {
                payload['price'] = product.baseInclVat;
                payload['discount_percentage'] = product.discountPercentage;
                payload['vat_percentage'] = product.vatPercentage;
                payload['vat_included'] = product.vatIncluded;
              }
            } else if (type === 'purchase') {
              const { status, data: prices } = await purchaseStore.getPurchaseProductSupplierPrice(id, getter.supplier_id);

              if (status === 'success') {
                payload['purchase_price'] = prices!.purchase_price;
                payload['reference'] = prices!.reference;
              }
            }

            try {
              const { data } = await ApiService.post('apiUrl', apiUrl, payload);
              await appStore.validateDocument(data.item_id, documentValidateIndexName);
            }
            finally {
              this.search.value = null;
              this.search.results = null;
              const searchBox = document.getElementById('searchProduct') as HTMLInputElement | null;
              searchBox!.value = '';
              this.search.scanner = false;
              this.search.options = [];

              appComponentStore.reloadComponent(component.componentConfig.name);

              nextTick(() => {
                document.getElementById('searchProduct')!.focus();
              })

              this.isLoading = false;
            }
          }
        }
      }
    },
    async getProductPrice(productId: number, customerId: number) {

      const response = {
        status: 'error',
        data: null,
      } as any

      try {
        const payload = {
          product_id: productId,
          customer_id: customerId
        };
        const { data } = await ApiService.post("apiUrl", `/product/customer-price`, payload);

        const item = data.result;

        const template = {
          // id: item.id,
          // title: item.title,
          baseInclVat: Number(item.price),
          discountInclVat: Number(item.discount_price),
          discountPercentage: Number(item.discount_percentage),
          vatPercentage: Number(item.vat_percentage),
          vatIncluded: Number(item.vat_included)
        }

        response.status = 'success'
        response.data = template;
      }
      catch (error) {
        //
      }

      return response;
    },
    async findProduct(value: any) {

      const response = {
        status: 'error',
        data: null
      } as any

      const formattedValue = removeSpaces(value);

      const payload: Payload = {
        q: formattedValue ?? '',
        sort: ['id:desc'],
        matchingStrategy: 'all',
        facets: ['*'],
        filter: [],
        offset: 0,
        limit: 20
      }

      const { data: searchResults } = await ApiService.post('apiUrlSearch', `/indexes/products/search`, payload);

      if (searchResults.hits.length) {
        response.status = 'success';
        response.data = searchResults.hits[0];
      }

      return response;
    }
  }
});