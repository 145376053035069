export const mergeData = (primaryData, relatedResults) => {
  relatedResults.forEach(result => {
    result.hits.forEach(item => {
      primaryData.forEach(dataItem => {
        if (dataItem[result.index.targetKey] === item[result.index.filterKey]) {
          console.log('Merging data for matching item');
          const fieldsToMerge = result.index.mergeFields && result.index.mergeFields.length > 0
            ? result.index.mergeFields
            : Object.keys(item); // If mergeFields is defined, use that; otherwise, merge all fields

          fieldsToMerge.forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(dataItem, key)) {
              dataItem[key] = item[key];
            }
          });
        }
      });
    });
  });

  return primaryData; // Return the adjusted data
};