export const collectTargetKeyValues = (dataItem: any, result: any, targetKey: string): any[] => {
  const collectedValues: any[] = [];

  // Itereer door de relatedResults hits en verzamel de waarden voor targetKey
  result.hits.forEach(item => {
    // Zoek naar overeenkomsten op basis van targetKey en filterKey
    if (dataItem[result.index.targetKey] === item[result.index.filterKey]) {
      const value = item[targetKey];

      // Voeg de waarde alleen toe als deze nog niet bestaat in de verzamelde waarden
      if (value !== undefined && !collectedValues.includes(value)) {
        collectedValues.push(value);
      }
    }
  });

  return collectedValues; // Return de verzamelde waarden
}